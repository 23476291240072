import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import {
  TeaserListContainer,
  TeaserListItem,
} from '../components/list-types/teasers-list'
import Teaser from '../components/display-types/teaser'
import SEO from '../components/seo'

export default function NewsListingPage({ data, pageContext: {
  breadcrumb: { crumbs },
  } }) {
  const micrositeContent = data.articles.edges.filter(
    thisMicrositesContent =>
      parseInt(
        thisMicrositesContent.node.relationships.field_microsite
          .drupal_internal__tid
      ) === parseInt(`${process.env.MICROSITE_TAXONOMY_TERM_ID}`)
  )
  return (
    <Layout>
      <SEO />
      <div className="layout-contained padding-horizontal">
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
        <h1>News</h1>
        <TeaserListContainer>
          {micrositeContent.map(item => (
            <TeaserListItem key={item.drupal_internal__nid}>
              <Teaser
                teaserTitle={item.node.title}
                teaserBody={item.node.field_microsite_teaser}
                teaserURL={item.node.field_microsite_url_slug}
              />
            </TeaserListItem>
          ))}
        </TeaserListContainer>
      </div>
    </Layout>
  )
}

export const NewsListingPageQuery = graphql`
  query NewsListingQuery {
    articles: allNodeMicrositeNews {
      edges {
        node {
          title
          field_microsite_teaser
          field_microsite_url_slug
          drupal_internal__nid
          relationships {
            field_microsite {
              drupal_internal__tid
            }
          }
        }
      }
    }
  }
`

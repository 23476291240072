import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { ArrowRightIcon } from '../icons'

const TeaserStyles = styled.article`
  .teaser__text {
    margin-bottom: 1.5rem;
  }
  .teaser__read-more {
    padding: 0.5rem 1rem;
    background-color: var(--color-brand);
    color: white;
    border: 1px solid var(--color-brand);
    letter-spacing: 0.25rem;
    text-decoration: none;
    svg {
      width: 1rem;
      height: 1rem;
      margin-left: 1rem;
      transform: translateY(0.1rem);
      transition: all 0.25s ease;
    }
    svg path {
      fill: white;
    }
    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--color-brand);
      background-color: white;
      svg {
        transform: translate(0.5rem, 0.1rem);
      }
      svg path {
        fill: var(--color-brand);
      }
    }
  }
`

export default function Teaser({ teaserTitle, teaserBody, teaserURL }) {
  return (
    <TeaserStyles>
      <Link to={teaserURL}><h2>{teaserTitle}</h2>
      </Link>
      <div
        className="teaser__text"
        dangerouslySetInnerHTML={{
          __html: teaserBody,
        }}
      />
      <Link className="teaser__read-more" to={teaserURL}>
        Read more
        <ArrowRightIcon />
      </Link>
    </TeaserStyles>
  )
}

import React from 'react'
import styled from 'styled-components'

const TeaserListContainerStyles = styled.ul`
  max-width: var(--width-medium);
  padding-left: 0;
  list-style-type: none;
`
const TeaserListItemStyles = styled.li`
  margin-bottom: 4rem;
  padding-top: 3rem;
  border-top: 1px solid var(--color-brand);
`
function TeaserListContainer({ children }) {
  return <TeaserListContainerStyles>{children}</TeaserListContainerStyles>
}

function TeaserListItem({ children, cardListItemKey }) {
  return (
    <TeaserListItemStyles key={cardListItemKey}>
      {children}
    </TeaserListItemStyles>
  )
}

export { TeaserListContainer, TeaserListItem }
